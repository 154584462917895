<script>
import { Bar } from "vue-chartjs";
import axios from "axios";

export default {
  extends: Bar,
  name: "Data",
  data() {
    return {
      data: {
        datasets: [
          {
            label: "全 ユニークユーザ",
            data: [],
            borderColor: "red",
            fill: false,
            type: "line",
            borderDash: [5, 5],
            lineTension: 0,
          },
          {
            label: "全 アクセス",
            data: [],
            borderColor: "red",
            fill: false,
            type: "line",
            lineTension: 0,
          },

          {
            label: "トップ ユニークユーザ",
            data: [],
            borderColor: "green",
            fill: false,
            type: "line",
            borderDash: [5, 5],
            lineTension: 0,
          },
          {
            label: "トップ アクセス",
            data: [],
            borderColor: "green",
            fill: false,
            type: "line",
            lineTension: 0,
          },

          {
            label: "不動産 ユニークユーザ",
            data: [],
            borderColor: "blue",
            fill: false,
            type: "line",
            borderDash: [5, 5],
            lineTension: 0,
          },
          {
            label: "不動産 アクセス",
            data: [],
            borderColor: "blue",
            fill: false,
            type: "line",
            lineTension: 0,
          },

          {
            label: "物件検索 ユニークユーザ",
            data: [],
            borderColor: "orange",
            fill: false,
            type: "line",
            borderDash: [5, 5],
            lineTension: 0,
          },
          {
            label: "物件検索 アクセス",
            data: [],
            borderColor: "orange",
            fill: false,
            type: "line",
            lineTension: 0,
          },
        ],
      },
      options: {
        maintainAspectRatio: false,
        scales: {
          xAxes: [
            {
              ticks: {
                display: true,
                fontSize: "14",
              },
            },
          ],
          yAxes: [
            {
              position: "left",
              ticks: {
                beginAtZero: true,
                fontSize: "12",
                stepSize: 1,
                min: 0,
              },
              scaleLabel: {
                display: true,
                labelString: "アクセス数",
              },
            },
            {
              position: "right",
              ticks: {
                beginAtZero: true,
                fontSize: "12",
                stepSize: 1,
                min: 0,
              },
              scaleLabel: {
                display: false,
              },
            },
          ],
        },
        legend: {
          display: true,
        },
        title: {
          display: true,
          position: "top",
          fontColor: "#333",
          fontSize: "18",
          padding: "10",
          text: ["アクセス数 推移"],
        },
      },
    };
  },
  props: {
    Notitle: Boolean,
    IsData: Boolean,
    allAccess: Boolean,
    topAccess: Boolean,
    realEstateAccess: Boolean,
    estateAccess: Boolean,
    allUnique: Boolean,
    topUnique: Boolean,
    realEstateUnique: Boolean,
    estateUnique: Boolean,
    term: String,
    reset: Boolean,
  },
  async mounted() {
    const response = await axios.post("/access/line");
    this.data.datasets[1].data = response.data.allAccess;
    this.data.datasets[3].data = response.data.topAccess;
    this.data.datasets[5].data = response.data.realEstateAccess;
    this.data.datasets[7].data = response.data.estateAccess;
    this.data.labels = response.data.labels;
    await this.maxAccess();
    this.renderChart(this.data, this.options);
  },
  methods: {
    async maxAccess() {
      let arr = [];
      arr = this.data.datasets[0].data
        ? arr.concat(this.data.datasets[0].data)
        : arr;
      arr = this.data.datasets[1].data
        ? arr.concat(this.data.datasets[1].data)
        : arr;
      arr = this.data.datasets[2].data
        ? arr.concat(this.data.datasets[2].data)
        : arr;
      arr = this.data.datasets[3].data
        ? arr.concat(this.data.datasets[3].data)
        : arr;
      arr = this.data.datasets[4].data
        ? arr.concat(this.data.datasets[4].data)
        : arr;
      arr = this.data.datasets[5].data
        ? arr.concat(this.data.datasets[5].data)
        : arr;
      arr = this.data.datasets[6].data
        ? arr.concat(this.data.datasets[6].data)
        : arr;
      arr = this.data.datasets[7].data
        ? arr.concat(this.data.datasets[7].data)
        : arr;
      await this.convertNum(Math.max(...arr));
    },
    convertNum(number) {
      const stringifiedBoolean = number.toString();
      const firstDigit = stringifiedBoolean[0];
      const remainingDigits = "0".repeat(stringifiedBoolean.length - 1);
      const convertedBoolean = parseInt(firstDigit + remainingDigits);

      this.options.scales.yAxes[0].ticks.stepSize = Math.ceil(
        convertedBoolean * 0.05
      );
      this.options.scales.yAxes[0].ticks.max = number;
      if (number > 0) {
        this.$emit("noneFalse");
      } else {
        this.$emit("noneTrue");
      }
      this.options.scales.yAxes[1].ticks.stepSize =
        this.options.scales.yAxes[0].ticks.stepSize;
      this.options.scales.yAxes[1].ticks.max =
        this.options.scales.yAxes[0].ticks.max;
    },
  },
  watch: {
    async allUnique(val) {
      if (this.reset) {
        return false;
      }
      this.$emit("resetTrue");
      if (val) {
        const data = {
          line: "allUnique",
          term: this.term,
        };
        const response = await axios.post("/access/line_add", data);
        this.data.datasets[0].data = response.data.dataset;
      } else {
        this.data.datasets[0].data = null;
      }
      await this.maxAccess();
      this.renderChart(this.data, this.options);
      this.$emit("resetFalse");
    },

    async allAccess(val) {
      if (this.reset) {
        return false;
      }
      this.$emit("resetTrue");
      if (val) {
        const data = {
          line: "allAccess",
          term: this.term,
        };
        const response = await axios.post("/access/line_add", data);
        this.data.datasets[1].data = response.data.dataset;
      } else {
        this.data.datasets[1].data = null;
      }
      await this.maxAccess();
      this.renderChart(this.data, this.options);
      this.$emit("resetFalse");
    },
    async topUnique(val) {
      if (this.reset) {
        return false;
      }
      this.$emit("resetTrue");
      if (val) {
        const data = {
          line: "topUnique",
          term: this.term,
        };
        const response = await axios.post("/access/line_add", data);
        this.data.datasets[2].data = response.data.dataset;
      } else {
        this.data.datasets[2].data = null;
      }
      await this.maxAccess();
      this.renderChart(this.data, this.options);
      this.$emit("resetFalse");
    },
    async topAccess(val) {
      if (this.reset) {
        return false;
      }
      this.$emit("resetTrue");
      if (val) {
        const data = {
          line: "topAccess",
          term: this.term,
        };
        const response = await axios.post("/access/line_add", data);
        this.data.datasets[3].data = response.data.dataset;
      } else {
        this.data.datasets[3].data = null;
      }
      await this.maxAccess();
      this.renderChart(this.data, this.options);
      this.$emit("resetFalse");
    },

    async realEstateUnique(val) {
      if (this.reset) {
        return false;
      }
      this.$emit("resetTrue");
      if (val) {
        const data = {
          line: "realEstateUnique",
          term: this.term,
        };
        const response = await axios.post("/access/line_add", data);
        this.data.datasets[4].data = response.data.dataset;
      } else {
        this.data.datasets[4].data = null;
      }
      await this.maxAccess();
      this.renderChart(this.data, this.options);
      this.$emit("resetFalse");
    },
    async realEstateAccess(val) {
      if (this.reset) {
        return false;
      }
      this.$emit("resetTrue");
      if (val) {
        const data = {
          line: "realEstateAccess",
          term: this.term,
        };
        const response = await axios.post("/access/line_add", data);
        this.data.datasets[5].data = response.data.dataset;
      } else {
        this.data.datasets[5].data = null;
      }
      await this.maxAccess();
      this.renderChart(this.data, this.options);
      this.$emit("resetFalse");
    },
    async estateUnique(val) {
      if (this.reset) {
        return false;
      }
      this.$emit("resetTrue");
      if (val) {
        const data = {
          line: "estateUnique",
          term: this.term,
        };
        const response = await axios.post("/access/line_add", data);
        this.data.datasets[6].data = response.data.dataset;
      } else {
        this.data.datasets[6].data = null;
      }
      await this.maxAccess();
      this.renderChart(this.data, this.options);
      this.$emit("resetFalse");
    },
    async estateAccess(val) {
      if (this.reset) {
        return false;
      }
      this.$emit("resetTrue");
      if (val) {
        const data = {
          line: "estateAccess",
          term: this.term,
        };
        const response = await axios.post("/access/line_add", data);
        this.data.datasets[7].data = response.data.dataset;
      } else {
        this.data.datasets[7].data = null;
      }
      await this.maxAccess();
      this.renderChart(this.data, this.options);
      this.$emit("resetFalse");
    },

    async term() {
      const data = {
        term: this.term,
      };
      this.data.datasets[0].data = null;
      this.data.datasets[1].data = null;
      this.data.datasets[2].data = null;
      this.data.datasets[3].data = null;
      this.data.datasets[4].data = null;
      this.data.datasets[5].data = null;
      this.data.datasets[6].data = null;
      this.data.datasets[7].data = null;

      const response = await axios.post("/access/line_term", data);
      this.data.datasets[1].data = response.data.allAccess;
      this.data.datasets[3].data = response.data.topAccess;
      this.data.datasets[5].data = response.data.realEstateAccess;
      this.data.datasets[7].data = response.data.estateAccess;
      this.data.labels = response.data.labels;
      await this.maxAccess();
      this.renderChart(this.data, this.options);
      this.$emit("resetFalse");
    },
  },
};
</script>

